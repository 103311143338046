import React from "react"
import DefaultLink from "components/defaultLink"

const CtaBand = ({ title, ctaIcon, ctaLabel, ctaLink, className }) => {
    return (
        <section className={"u-bg-primary " + (className || '')}>
            <div className="u-flex u-flex-center-vt u-flex-center-hz u-pd-vt-xxl">
                <h3 className="c-h3 u-secondary u-center" dangerouslySetInnerHTML={{__html: title}}></h3>
            </div>
            <div className="u-flex u-flex-center-hz u-absolute u-left-0 u-width-full" style={{ transform: 'translateY(-50%)' }}>
                <DefaultLink link={ctaLink} className={"c-btn c-btn--secondary u-pd-hz-l"}>
                    {ctaIcon && <span className={ctaIcon + " u-primary u-mg-right-s"}></span>}
                    <span className="u-font-adjustment">{ctaLabel}</span>
                </DefaultLink>
            </div>
        </section>
    )
}
export default CtaBand
