import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Page from "components/page"
import Layout from "components/layout"
import SEO from "components/seo"
import YoutubeVideo from "components/youtubeVideo"
import ContentBand1 from "components/blocks/content-band-1"
import Play from 'components/blocks/play'

export const AboutPage = props => {
    const data = useStaticQuery(graphql`query{
        aboutPage: file(sourceInstanceName: {eq: "about-page"}, name: {eq: "about-page"}) {
            id
            name
            childMarkdownRemark {
                html
                frontmatter {
                    title
                    video_url
                    seo {
                        title
                        description
                    }
                }
            }
        }
        blockDonate: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "donate"}) {
            id
            childMarkdownRemark {
                id
                frontmatter {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 600, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    button {
                        text
                        url
                    }
                }
                html
            }
        }
    }`)
    const blockDonate = data.blockDonate.childMarkdownRemark
    let aboutPage = {
        id: data.aboutPage.id,
        name: data.aboutPage.name,
        html: data.aboutPage.childMarkdownRemark.html,
        ...data.aboutPage.childMarkdownRemark.frontmatter
    }

    return (
        <>
            <SEO page={aboutPage.seo} />
            <Page>

                <Layout {...props} className={"c-content u-overflow-hidden"}>
                    <section className="c-headband u-pd-top-navbar">
                        <div className="l-container-md">
                            <div className="c-headband__content u-center u-pd-vt-m u-pd-vt-xl@main">
                                <h1 className="c-h1" dangerouslySetInnerHTML={{ __html: aboutPage.title }}></h1>
                            </div>
                        </div>
                    </section>

                    <section className="u-pd-top-xl">
                        <div className="l-container-lg u-pd-hz-l u-pd-hz-xxl@main">
                            {aboutPage.video_url && (
                                <YoutubeVideo url={aboutPage.video_url}
                                    className={"c-video--sized"}
                                    playerProps={{
                                        playing: false,
                                        controls: true,
                                        light: true,
                                        config: {
                                            youtube: {
                                                playerVars: {
                                                    color: "#EA6548",
                                                    autoplay: 0,
                                                    controls: 1,
                                                    fs: 0,
                                                    modestbranding: 1,
                                                },
                                            }
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </section>

                    <section className="u-pd-bottom-l">
                        <div className="l-container-lg u-pd-hz-l u-pd-hz-xxl@main">
                            <div className="c-wysiwyg" dangerouslySetInnerHTML={{ __html: aboutPage.html }}></div>
                        </div>
                    </section>

                    <Play />

                    <ContentBand1
                        className={"u-pd-vt-l@main"}
                        title={blockDonate.frontmatter.title}
                        desc={blockDonate.html}
                        ctaLabel={blockDonate.frontmatter.button.text}
                        ctaLink={blockDonate.frontmatter.button.url}
                        url={blockDonate.frontmatter.image.childImageSharp.fluid}
                    />
                </Layout>
            </Page>
        </>
    )
}

export default AboutPage