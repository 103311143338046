import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CtaBand from "./cta-band"

export const Play = React.memo(() => {
    const data = useStaticQuery(graphql`query{
        blockPlay: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "play"}) {
            id
            childBlocksYaml {
                title
                button {
                    text
                    url
                }
            }
        }
    }`)
    const { title, button } = data.blockPlay.childBlocksYaml

    return (
        <div className="l-container">
            <CtaBand
                title={title}
                ctaIcon="icon-FLECHE_CTA"
                ctaLabel={button.text}
                ctaLink={button.url}
                className={"u-pd-hz-m u-mg-bottom-xl"}
            />
        </div>
    )
})

export default Play